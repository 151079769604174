<template>
  <v-container fluid>
    <v-card tile>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <SelectedDinamicField
              :setter-model="sucursal_id"
              :item-action="SelectedSucursalField"
              :on-success="actionFieldData"
            />
          </v-col>
          <v-col
            v-if="showAlmacen"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <SelectedDinamicField
              :setter-model="almacen_id"
              :item-action="SelectedAlmacenField"
              :on-success="actionFieldData"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-tabs
      v-model="tabMain"
      background-color="primary"
      dark
    >
      <v-tab
        v-for="(item, index) in estatus"
        :key="index"
        @click="getStatusAlmacen(item.id)"
      >
        {{ item.nombre }} ({{ item.estatus }})
      </v-tab>
    </v-tabs>
    <v-row dense>
      <v-col cols="12">
        <DataTableMainServer
          v-if="showTable"
          :datatable="table"
          :on-success="successAction"
          :items-action="tableAction"
          :updatepagination="params"
          :on-pagination="pagePagination"
          :on-search="getSearchPagePagination"
        />
      </v-col>
    </v-row>
    <MainFormDialog
      v-if="get_dialogMain.dialog"
      :model="object"
      :params="paramsDialog"
      :on-success="ResponseForm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataTableMainServer from '@/components/tables/DataTableMainServer'
import MainFormDialog from '@/components/dialogs/MainFormDialog'
import SelectedDinamicField from '@/components/fields/SelectedDinamicField'
import { UserSession } from '@/store/modules/config'
import { Permission } from '@/mixins/permissionMain'
export default {
  name: 'InvComprasView',
  components: {
    DataTableMainServer,
    MainFormDialog,
    SelectedDinamicField
  },
  mixins: [Permission],
  data: () => ({
    tabMain: null,
    estatus: [],
    estatusMain: 0,
    sucursal_id: 0,
    almacen_id: 0,
    table: {
      header: {
        options: [
          { title: 'Refrescar', icon: 'mdi-cached', color: 'primary', action: 'refreshData', code: 'libre', hidden: '' },
          {
            title: 'Reportes',
            icon: 'mdi-cloud-download',
            color: 'light-blue lighten-1',
            code: 'inventario.reporte',
            options: [
              { title: 'Reporte Disponible General', icon: 'mdi-clipboard-text', color: 'yellow darken-3', action: 'ExportGralInventory', code: 'inventario.reporte.general-disponible-excel' }
            ]
          }
        ],
        titles: [
          { text: 'Folio', value: 'folio', class: 'black--text' },
          { text: 'Sucursal', value: 'almacen.sucursal.nombre', class: 'black--text' },
          { text: 'Almacen', value: 'almacen.nombre', class: 'black--text' },
          { text: 'Codigo Barras', value: 'caracteristicas.codigo', align: 'center', class: 'black--text' },
          // { text: 'Producto', value: 'producto.caracteristicas.descripcion', class: 'black--text' },
          { text: 'Articulo', value: 'caracteristicas.descripcion', class: 'black--text' },
          { text: 'minimo del Limite', value: 'cantidad_minima', align: 'center', class: 'black--text' },
          { text: 'Unidad', value: 'udm.nombre', class: 'black--text' },
          { text: 'Cantidad', value: 'inventarios.checkin', align: 'center', class: 'black--text' },
          { text: 'EsFacturado', value: 'EstatusFacturado', align: 'center', class: 'black--text' },
          { text: 'Estatus', value: 'Status', class: 'black--text' },
          { text: 'Activo', value: 'Activo', class: 'black--text' },
          { text: '', value: 'Actions', class: 'black--text' }
        ],
        loading: false
      },
      body: {
        data: [],
        actions: []
      },
      footer: {
        paging: true
      }
    },
    paramsDialog: {},
    object: {},
    params: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 10,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: []
    },
    default: { groupBy: [], groupDesc: [], itemsPerPage: 10, multiSort: false, mustSort: false, page: 1, sortBy: [], sortDesc: [] },

    SelectedSucursalField: { field: 'selectDataServer', name: 'Sucursales', nameid: 'sucursal_id', url: '/empresa/sucursales', parmas: '', cols: 3 },
    SelectedAlmacenField: { field: 'selectDataServer', name: 'Almacenes', nameid: 'almacen_id', url: '/inventario/almacenes/list-sucursal', parmas: '', cols: 3 },
    showAlmacen: false,
    showSucursal: false,
    showTable: false
  }),
  computed: {
    ...mapGetters(['get_urls', 'get_dialogMain', 'get_objects', 'get_objectPagination'])
  },
  watch: {
    get_urls (val) {
      this.ExecuteUrl('GET/inventario/almacenes/config/almacen')
    },
    get_objects (rows) {
      if (rows) {
        this.estatus = []
        this.estatus = rows
        this.estatusMain = rows[0].id
        this.sucursal_id = UserSession().sucursal_id
        this.almacen_id = UserSession().almacen_id
        // if (this.showMainPermiss('')) { this.showSucursal = true }
      }
    },
    get_objectPagination (rows) {
      if (rows) {
        this.table.body.data = []
        const title = this.table.header.titles.find(a => a.text === 'Cantidad')
        if (title) {
          if (this.params.status === 10) title.value = 'inventarios.checkin'
          if (this.params.status === 11) title.value = 'inventarios.stock'
          if (this.params.status === 12) title.value = 'inventarios.picking'
          if (this.params.status === 13) title.value = 'inventarios.closeout'
        }
        this.table.header.loading = false
        this.table.body.data = rows.data
        this.table.body.totalData = rows.total
      }
    }
  },
  mounted () {
    this.ExecuteUrl('GET/inventario/almacenes/config/almacen')
    this.ShowPermisos({ permisos: JSON.parse(localStorage.getItem('permisos')), tableMain: this.table })
  },
  methods: {
    ...mapActions(['GETUrlServices', 'GETListObjectsService', 'GETObjectService',
      'POSTListObjectsPaginationService', 'ExportDataService']),
    ...mapMutations(['SHOW_DIALOG_MAIN', 'CLEAR_LIST_DATA']),
    // *==========*==========*==========*    Methods Table Pagination    *==========*==========*==========* \\
    pagePagination (val) {
      this.table.header.loading = true
      this.params = (val) || this.default
      this.params.status = this.estatusMain
      this.params.sucursal_id = this.sucursal_id
      this.params.almacen_id = this.almacen_id
      this.ExecuteUrlTwo('POST/inventario/articulos/pagination/show')
    },
    getSearchPagePagination (val) {
      this.table.header.loading = true
      // val.page = 1
      this.params = val
      this.params.status = this.estatusMain
      this.params.sucursal_id = this.sucursal_id
      this.params.almacen_id = this.almacen_id
      this.ExecuteUrlTwo('POST/inventario/articulos/pagination/show')
      // this.pages = val
    },
    getStatusAlmacen (id) {
      this.table.header.loading = true
      this.params.status = id
      this.estatusMain = id
      this.ExecuteUrlTwo('POST/inventario/articulos/pagination/show')
    },
    actionFieldData (obj) {
      this[obj.id] = (obj.data) ? obj.data : null
      if (obj.id === 'sucursal_id') {
        this.almacen_id = null
        this.params.almacen_id = null
        if (obj.data) {
          this.showAlmacen = false
          this.SelectedAlmacenField.params = `/${obj.data}`
          setTimeout(() => { this.showAlmacen = true }, 1)
        } else {
          this.sucursal_id = 0
          this.SelectedAlmacenField.params = ''
          this.showAlmacen = false
        }
      }
      if (!this.showTable) { this.showTable = true } else { this.refreshData({}) }
    },
    showMainPermiss (permiso) {
      return this.ReadOnlyPermiso(JSON.parse(localStorage.getItem('permisos')), permiso)
    },
    // *==========*==========*==========*==========*==========*==========*==========*==========*==========* \\
    // *==========*==========*==========*==========*==========*==========*==========*==========*==========* \\

    successAction (item) {
      this[item.action](item)
    },
    refreshData (item) {
      this.table.header.loading = true
      this.params.status = this.estatusMain
      this.params.sucursal_id = this.sucursal_id
      this.params.almacen_id = this.almacen_id
      this.ExecuteUrlTwo('POST/inventario/articulos/pagination/show')
    },
    newData (item) {
      this.table.header.loading = true
    },

    tableAction (item) {
      this[item.action](item)
    },
    toEntry (item) {
      this.object = item
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        objcettwo: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({
        type: 'entry',
        form: 'process',
        with: 650,
        title: 'INGRESAR INVENTARIO A ENTRADA'
      })
    },
    toStock (item) {
      this.object = item
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        objcettwo: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({
        type: 'stock',
        form: 'process',
        with: 650,
        title: 'ENVIAR A STOCK'
      })
    },
    toExpire (item) {
      this.object = item
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        // data: {}, setmodel: true,
        objcettwo: true,
        returnObject: true
      }
      const nombre = item.item.caracteristicas.descripcion
      this.SHOW_DIALOG_MAIN({ type: 'expired', form: 'process', with: 650, title: `CADUCADO - ${nombre}` })
    },
    toDisarmProduct (item) {
      this.object = item
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        // data: {}, setmodel: true,
        objcettwo: true,
        returnObject: true
      }
      const nombre = item.item.caracteristicas.descripcion
      this.SHOW_DIALOG_MAIN({ type: 'desarmar', form: 'process', with: 650, title: `DESARMAR ARTICULO ${nombre}` })
    },
    toPickIng (item) {
      this.object = item
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        objcettwo: true,
        returnObject: true
      }
      this.SHOW_DIALOG_MAIN({ type: 'stock', form: 'process', with: 650, title: 'ENVIAR A VENDER' })
    },
    toCloseOut (item) {
      this.object = item
      this.paramsDialog = {
        urlprocess: this.get_urls['GET/inventario/options[/{status_id}]'],
        url: this.get_urls['POST/inventario/execute'],
        message: 'Registro Agregado Correctamente',
        // data: { articulo_id: item.item.id }, setmodel: true,
        objcettwo: true,
        returnObject: true
      }
      const nombre = item.item.caracteristicas.descripcion
      this.SHOW_DIALOG_MAIN({ type: 'stock', form: 'process', with: 650, title: `SALIDA - ${nombre}` })
    },
    ExportGralInventory (item) {
      const router = this.get_urls['POST/inventario/reportes/general-disponible']
      this.params.nombre = 'InventarioGeneral'
      this.ExportDataService({ url: router, data: this.params })
    },
    // *==========*==========*==========*==========*==========*==========*==========*==========*==========* \\
    ResponseForm ({ name, model }) {
      let finded = -1
      this.table.body.data.map((articulo, index) => {
        if (articulo.id === model.id) finded = index
      })
      if (finded !== -1) {
        Object.assign(this.table.body.data[finded], model)
      } else {
        this.table.body.data.push(model)
        this.table.body.totalData = this.table.body.data.length
      }
    },
    ExecuteUrl (url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.GETListObjectsService({ url: router, data: this.params })
      } else {
        this.GETUrlServices()
      }
    },
    ExecuteUrlTwo (url) {
      if (this.get_urls != null) {
        const router = this.get_urls[url]
        this.POSTListObjectsPaginationService({ url: router, data: this.params })
      } else {
        this.GETUrlServices()
      }
    }
    // *==========*==========*==========*==========*==========*==========*==========*==========*==========* \\
  }
}
</script>

<style>

</style>
